import React from "react";
import ButtonComponent from "./buttonComponent";
export default function PlatformsComponent({ navigation, handleShow }) {
  return (
    <div className="platforms-component">
      <div className="inner-platforms-component">
        <h3>Platforms</h3>
        <div className="platforms-component-child">
          <div
            className="platforms-component-card"
            onClick={() => navigation("/instagram-dashboard")}
          >
            <div className="platforms-component-card-child">
              <img
                src={"./Assets/Images/landing/socail/instragram.png"}
                alt=""
              />
              <h5>Instagram</h5>
            </div>
            <ButtonComponent
              text={"Select"}
              onClick={() => navigation("/instagram-dashboard")}
              btnclass="btn-background mb-2 platform-socail-child"
            />
          </div>
          <div
            className="platforms-component-card"
            onClick={() => navigation("/sound-cloud-dashboard")}
          >
            <div className="platforms-component-card-child">
              <img
                src={"./Assets/Images/landing/socail/soundclould.png"}
                alt=""
              />
              <h5>SoundCloud</h5>
            </div>
            <ButtonComponent
              text={"Select"}
              onClick={() => navigation("/sound-cloud-dashboard")}
              btnclass="btn-background mb-2 platform-socail-child"
            />
          </div>
          <div
            className="platforms-component-card"
            onClick={() => handleShow()}
          >
            <div className="platforms-component-card-child">
              <img src={"./Assets/Images/landing/socail/shopify.png"} alt="" />
              <h5>Spotify</h5>
            </div>
            <ButtonComponent
              text={"Select"}
              onClick={() => handleShow()}
              btnclass="btn-background mb-2 platform-socail-child"
            />
          </div>
          <div
            className="platforms-component-card"
            onClick={() => navigation("/tiktok-dashboard")}
          >
            <div className="platforms-component-card-child">
              <img src={"./Assets/Images/landing/socail/tiktok.png"} alt="" />
              <h5>TikTok</h5>
            </div>
            <ButtonComponent
              text={"Select"}
              onClick={() => navigation("/tiktok-dashboard")}
              btnclass="btn-background mb-2 platform-socail-child"
            />
          </div>
          <div
            className="platforms-component-card"
            onClick={() => navigation("/youtube-dashboard")}
          >
            <div className="platforms-component-card-child">
              <img src={"./Assets/Images/landing/socail/youtube.png"} alt="" />
              <h5>YouTube</h5>
            </div>
            <ButtonComponent
              text={"Select"}
              onClick={() => navigation("/youtube-dashboard")}
              btnclass="btn-background mb-2 platform-socail-child"
            />
          </div>
          <div
            className="platforms-component-card"
            onClick={() => navigation("/twitter-dashboard")}
          >
            <div className="platforms-component-card-child">
              <div className="twitter-social-icom">
                <img
                  src={"./Assets/Images/landing/socail/twitter.webp"}
                  alt=""
                />
              </div>
              <h5>Twitter</h5>
            </div>
            <ButtonComponent
              text={"Select"}
              onClick={() => navigation("/twitter-dashboard")}
              btnclass="btn-background mb-2 platform-socail-child"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
