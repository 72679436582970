import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonComponent from "../Components/buttonComponent";
import DrawerDialog from "../Components/drawer";
import ProfileComponent from "../Pages/profileComponent";
import MenuComponent from "../Components/MenuComponent";
import { Tooltip } from "antd";
import { useAuth0 } from "@auth0/auth0-react";

function HeaderUI({ whiteLogo, userData, setApiCall, setUserData, apiCall }) {
  const [isShow, setIsShow] = React.useState(false);
  const { isAuthenticated, isLoading } = useAuth0();

  const handleClose = () => setIsShow(false);
  const handleopen = () => setIsShow(true);
  const navigation = useNavigate();
  return (
    <React.Fragment>
      <nav
        className={`navbar  ${
          whiteLogo ? "navbar-transparent" : "navbar-header"
        }`}
      >
        <div className="navbar-container">
          <Link
            className="navbar-brand"
            // to={userData?.user_metadata?.subscriptionPlan ? "/home" : "/"}
            to={"/"}
          >
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img src="/Assets/Images/newLogo.png" alt="" />{" "}
              </div>
            </>
          </Link>
          <div className="profile-Button-Parent">
            {/* remanning balance  */}

            {/* {localStorage?.getItem("accessToken") && (
              <div className="remaining-credits-parent">
                <h6 className="Credits-header-text" style={{ marginRight: 5 }}>
                  Remaining Credits:
                </h6>
                <div className="inner-Remaining">
                  <span>{userData?.user_metadata?.remainingCredits || 0}/</span>
                  <span>{userData?.user_metadata?.credits || 0}</span>
                </div>
              </div>
            )} */}
            {isLoading && !userData?.user_id ? null : userData?.user_id ? (
              <>
                {/* Menu component will insert here  */}
                {/* <MenuComponent
                  picture={userData?.picture}
                  name={
                    userData?.user_id?.split("|")[0] === "auth0"
                      ? userData?.username
                      : userData?.name
                  }
                /> */}

                {/* Menu component will insert here  */}
                {userData?.user_id && (
                  <div className="remaining-credits-parent">
                    <Tooltip
                      placement="bottomLeft"
                      rootClassName="tooltip-credit-details"
                      // className="tooltip-credit-details"
                      title={`Credits are used to generate the contact information of your desired leads. It costs 1 credit for each Email address, Instagram link, and TikTok link.`}
                      arrow={true}
                    >
                      <h6
                        className="header-remaining-text"
                        style={{ marginRight: 5 }}
                      >
                        Remaining credits:
                      </h6>
                    </Tooltip>

                    <div className="header-remaining-credits">
                      <span>
                        {userData?.user_metadata?.remainingCredits || 0}/
                      </span>
                      <span>{userData?.user_metadata?.credits || 0}</span>
                    </div>
                  </div>
                )}
                <MenuComponent
                  toast={toast}
                  navigation={navigation}
                  userData={userData}
                  apiCall={apiCall}
                  setApiCall={setApiCall}
                  setUserData={setUserData}
                  picture={userData?.picture}
                  name={
                    userData?.user_id?.split("|")[0] === "auth0"
                      ? userData?.username
                      : userData?.name
                  }
                />

                {/* logout button  */}

                {/* <ButtonComponent
                  onClick={() => {
                    // webAuth.logout({
                    //   returnTo: window.location.origin,
                    //   clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
                    // });
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("stripe_customer_id");
                    localStorage.removeItem("auth-Id");
                    toast.success("Logout successfully");
                    setApiCall(!apiCall);
                    navigation("/");
                  }}
                  // src={"/Assets/Images/Logout.png"}
                  style={{
                    height: "40px",
                    width: "120px",
                    fontSize: "12px",
                    marginLeft: "10px",
                  }}
                  text={"Logout"}
                  btnclass="btn-background login-Height"
                /> */}

                {/* <div className="hamburger-icon" onClick={() => handleopen()}>
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="0"
                    viewBox="0 0 15 15"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.5 3C1.22386 3 1 3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3H1.5ZM1 7.5C1 7.22386 1.22386 7 1.5 7H13.5C13.7761 7 14 7.22386 14 7.5C14 7.77614 13.7761 8 13.5 8H1.5C1.22386 8 1 7.77614 1 7.5ZM1 11.5C1 11.2239 1.22386 11 1.5 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7761 13.7761 12 13.5 12H1.5C1.22386 12 1 11.7761 1 11.5Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div> */}
              </>
            ) : (
              <Link to={"/"} style={{ textDecoration: "none" }}>
                <ButtonComponent
                  style={{ height: "40px" }}
                  text={"Login"}
                  btnclass="btn-background login-Height"
                />
              </Link>
            )}
          </div>
        </div>
      </nav>
      {/* <DrawerDialog
        Component={ProfileComponent}
        navigation={navigation}
        userData={userData}
        apiCall={apiCall}
        setApiCall={setApiCall}
        isShow={isShow}
        handleClose={handleClose}
      /> */}
    </React.Fragment>
  );
}

export default HeaderUI;
