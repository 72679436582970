import React, { useEffect, useState } from "react";
import { Select, Space } from "antd";
const provinceData = ["Zhejiang", "Jiangsu"];
const cityData = {
  Zhejiang: ["Hangzhou", "Ningbo", "Wenzhou"],
  Jiangsu: ["Nanjing", "Suzhou", "Zhenjiang"],
};

export const SelectComponent = ({
  disabled,
  PreviousListFilterOnChange,
  PreviousListText,
}) => {
  const [data, setData] = useState([
    {
      label: "Filter",
      value: "Filter",
    },
  ]);
  const [cities, setCities] = useState();
  const [secondCity, setSecondCity] = useState();
  const handleProvinceChange = (value) => {
    setCities(cityData[value]);
    setSecondCity(cityData[value][0]);
  };
  useEffect(() => {
    if (PreviousListText?.length) {
      let arr = [
        {
          label: "Filter",
          value: "Filter",
        },
      ];
      PreviousListText.map((province) =>
        arr?.push({
          label: province?.search,
          value: province?.search,
        })
      );
      setData(arr);
    }
  }, [PreviousListText]);
  const onSecondCityChange = (value) => {
    setSecondCity(value);
  };
  return (
    <Space wrap>
      <div className="custom-select">
        <Select
          disabled={disabled}
          defaultValue={data[0]}
          style={{
            width: 220,
            border: "none",
            outline: "none",
            boxShadow: "none",
            opacity: disabled ? 0.5 : 1,
          }}
          // suffixIcon={<FastForwardOutlined />}
          // className='custom-select'
          key={data.map((province, index) => index)}
          onChange={PreviousListFilterOnChange}
          options={data.map((province) => province)}
        ></Select>
        <img src="./Assets/Images/sorter.png" className="select-icon" alt="" />
      </div>
    </Space>
  );
};
