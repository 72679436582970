import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InputComponent from "../Components/inputComponent";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function FilterMenu({
  setFilterObj,
  filterObj,
  platform,
  disabledButton,
  disableScrapping,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        className="filter-icon"
        id="search"
        style={disableScrapping ? { opacity: 0.5 } : { opacity: 1 }}
        onClick={!disableScrapping && handleClick}
      >
        <img src="./Assets/Images/filter.png" alt="" />
      </div>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <div className="filter-menu-item">
          <div className="filter-menu-label">
            {platform === "Spotify playlists" ? (
              <span>
                Mininum # of <br /> likes
              </span>
            ) : platform === "YouTube" ? (
              <span>
                Mininum # of <br /> subscribers
              </span>
            ) : (
              <span>
                Mininum # of <br /> followers
              </span>
            )}
          </div>
          <div>
            <input
              type="number"
              disabled={disabledButton}
              name=""
              value={filterObj?.min_followers}
              id=""
              onChange={(ev) =>
                setFilterObj({ ...filterObj, min_followers: ev.target.value })
              }
              placeholder="00"
              className="filter-menu-input"
            />
          </div>
        </div>
        {platform === "YouTube" ||
        platform === "Spotify playlists" ||
        platform === "SoundCloud" ||
        platform === "Tiktok" ||
        platform === "Spotify artists" ||
        platform === "Instagram" ||
        platform === "Twitter" ? null : (
          <div className="filter-menu-item">
            <div className="filter-menu-label">
              min # of <br /> following
            </div>
            <div>
              <input
                disabled={disabledButton}
                type="number"
                name=""
                id=""
                placeholder="00"
                className="filter-menu-input"
                value={filterObj?.min_following}
                onChange={(ev) =>
                  setFilterObj({ ...filterObj, min_following: ev.target.value })
                }
              />
            </div>
          </div>
        )}
        {/* <div className="filter-menu-item">
          <div className="filter-menu-label">
            Specific <br /> username
          </div>
          <div>
            <input
              type="text"
              name=""
              id=""
              placeholder="johndoe"
              className="filter-menu-input"
            />
          </div>
        </div> */}
      </StyledMenu>
    </>
  );
}
