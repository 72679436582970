import { Tooltip } from "antd";
import UpgradeModal from "../../Components/UpgradeModal";
const CustomTitle = ({ row }) => (
  <Tooltip placement="topLeft" title={row} arrow={true}>
    <div className="platform-text-show">{row}</div>
  </Tooltip>
);
const SoundCloud = (
  userData,
  setUserData,
  disabledButton,
  upgradeModal,
  setupgradeModal,
  loader,
  setLoader,
  loaderMsgId,
  setLoaderMsgId
) => {
  let obj = {
    name: "SoundCloud",
    url: "sound-cloud-dashboard",
    previousDataUrl: (user) => `/soundcloud-results/?user=${user}`,
    websocketUrl: `${process.env.REACT_APP_WEBSOCKET}/soundcloud-socket`,
    websocketTestUrl: `${process.env.REACT_APP_API_URL}/soundcloud-test-socket`,
    columns: [
      // ❐
      {
        name: "URL",
        selector: (row) => row.url,
        cell: (row) => (
          <Tooltip placement="topLeft" title={`${row.url}`} arrow={true}>
            <a
              href={row.url}
              className="platforms-Url platform-text-show"
              target="_blank"
            >
              {row.url}
            </a>
          </Tooltip>
        ),
        value: "url",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Name",
        selector: (row) => row.name,
        cell: (row) => <CustomTitle row={row.name} />,
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Followers",
        selector: (row) => row.followers,
        cell: (row) => <CustomTitle row={row.followers} />,
        value: "followers",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Email",
        selector: (row) => row?.email,
        cell: (row) => <CustomTitle row={row.email} />,
        // cell: (row) => (
        //   <Tooltip placement="topLeft" title={row?.email} arrow={true}>
        //     <div className="platform-text-show">
        //       {row?.email?.length &&
        //         row?.email?.map((url, index) => (
        //           <span>
        //             {url}
        //             {row?.email?.length - 1 === index ? "" : ","}{" "}
        //           </span>
        //         ))}
        //     </div>
        //   </Tooltip>
        // ),
        value: "email",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Other emails",
        selector: (row) => row?.other_emails,
        cell: (row) => (
          <Tooltip
            placement="topLeft"
            title={row?.other_emails?.join(" , ")}
            arrow={true}
          >
            <div className="platform-text-show">
              {row?.other_emails?.length
                ? row?.other_emails?.map((url, index) => (
                    <a
                      href={`mailto:${url}`}
                      className="platforms-Url platform-text-show"
                      target="_blank"
                    >
                      {url} {row?.other_emails?.length - 1 === index ? "" : ","}{" "}
                    </a>
                  ))
                : null}
            </div>
          </Tooltip>
        ),
        value: "other_emails",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Send message",
        selector: (row) => (
          <UpgradeModal
            disabledButton={disabledButton}
            name={"Sound Cloud"}
            routesname={"sound-cloud"}
            src="/Assets/Images/components/Sendmsg.png"
            socailIcon={"soundclooud"}
            premium={
              userData?.user_metadata?.subscriptionPlan === "Pro" ? true : false
            }
            setLoaderMsgId={setLoaderMsgId}
            loaderMsgId={loaderMsgId}
            cookieName={"soundCloud_oauth_token"}
            userData={userData}
            uid={row?.c_id}
            setUserData={setUserData}
            chatAPItUrl={`${process.env.REACT_APP_API_URL}/soundcloud-send-message`}
            upgradeModal={upgradeModal}
            setupgradeModal={setupgradeModal}
            loader={loader}
            setLoader={setLoader}
          />
        ),
        value: "Send Message",
        style: {
          width: "50px", // override the row height
        },
        sortable: false,
      },
    ],
  };
  return obj;
};
export { SoundCloud };
