import React, { useState, useEffect, useCallback, useMemo, memo } from "react";
import BreadCrumb from "./BreadCrumb";
import DataTable from "react-data-table-component";
import FilterComponent from "./FilterComponent";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Form } from "react-bootstrap";
import LoaderUI from "./LoaderUI";
import { w3cwebsocket } from "websocket";
import { getPreviousData, showCredits } from "../Data/Api/dashboard";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CustomPagination } from "../Components/CustomPagination";
import {
  // connectToWebsocket,
  disconnectFromWebsocket,
} from "../Data/Api/dashboard";
import HeaderUI from "./HeaderUI";
import { Route, Routes, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FooterComponent from "../Components/footerComponent";
import { SelectComponent } from "../Components/Select";
import { Processing } from "../Components/Processing";
import CSVModal from "../Components/CSVModal";
import DownloadModal from "../Components/downloadModal";
import LinkIdModal from "../Components/LinkIdModal";
import Loader from "../Components/loader";
import ButtonComponent from "../Components/buttonComponent";
import CircularUnderLoad from "../Components/CircularProgess";
import CircularProgress from "../Components/CircularProgess";
import InputComponent from "../Components/inputComponent";
import UpgradeModal from "../Components/UpgradeModal";
import UpdateModal from "../Components/updateModal";
import PricingModal from "../Components/PricingModal";
import { Table } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

export default function Dashboard({
  isExpire,
  data: data2,
  // data,
  userData,
  setUserData,
  setApiCall,
  apiCall,
  userInfo,
  messageModal,
  setMessageModal,
  messageInfo,
  setMessageInfo,
  apiToken,
  disabledButton,
  setDisabledButton,
  disableSocket,
}) {
  const [data, setData] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pending, setPending] = useState(true);
  const [downloadPDFModal, setDownloadPDFModal] = useState(false);
  const [downloadCSVModal, setDownloadCSVModal] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [searchScrappedData, setSearchScrappedData] = useState("");
  const [filterObj, setFilterObj] = useState({
    email: false,
    instagram: false,
    tiktok: false,
  });
  const [filter, setFilter] = useState({
    min_followers: 0,
    min_following: 0,
  });
  const [filterTextclick, setFilterTextclick] = useState("");
  const [filterTextSelect, setFilterTextSelect] = useState("");
  const [customSorttext, setcustomSorttext] = useState("asc");
  const [filteredData, setFilteredData] = useState([]);
  const [filteredSearchData, setFilteredSearchData] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [disableScrapping, setDisableScrapping] = useState(false);
  const [disableScrappingImport, setDisableScrappingImport] = useState(false);
  const [filteredOldData, setFilteredOldData] = useState([]);
  const [customSortcount, setcustomSortcount] = useState(1);
  const [searchApi, setSearchApi] = useState(false);
  const [importSearch, setImportSearch] = useState(false);
  const [search, setSearch] = useState(null);
  const [client, setClient] = useState(null);
  const [pricingModal, setpricingModal] = useState(false);
  const [files, setFiles] = useState("");
  const [dataToggle, setDataToggle] = useState(false);
  const [columns_data_for_export, setColumns_data_for_export] = useState(false);
  const [rows_data_for_export, setRows_data_for_export] = useState(false);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const [upgradeModal, setupgradeModal] = useState(false);
  const [upgradeAfter, setupgradeAfter] = useState("");
  const { isAuthenticated, isLoading } = useAuth0();
  var url = new URL(window.location);
  let open = url.searchParams.get("open");
  useEffect(() => {
    console.log("ldfjkljlkjfkljkfjkl jkjdk jljkljkl");
    if (!disabledButton) {
      if (typeof data2 === "function") {
        let getData = data2();
        setData(getData);
        if (getData) {
          if (userInfo) {
            getPreviousData(
              getData?.previousDataUrl(userInfo?.user_id),
              setTableData,
              setPending
            );
          }
        }
      } else {
        setData(data2);
        if (data2) {
          if (userInfo) {
            getPreviousData(
              data2?.previousDataUrl(userInfo?.user_id),
              setTableData,
              setPending
            );
          }
        }
      }
    } else {
      if (typeof data2 === "function") {
        let getData = data2();
        setData(getData);
      } else {
        setData(data2);
      }
    }
  }, [dataToggle, userInfo, disabledButton]);
  useEffect(() => {
    if (disableSocket) {
      if (typeof data2 === "function") {
        let getData = data2();
        setData(getData);
      } else {
        setData(data2);
      }
    } else {
      if (typeof data2 === "function") {
        let getData = data2();
        setData(getData);
      } else {
        setData(data2);
      }
    }
  }, [disableSocket]);
  const scrapperCheckFn = () => {
    // let checkSocket = w3cwebsocket(data.websocketTestUrl);
    // console.log(checkSocket);
    // checkSocket.onopen = (check) => {
    //   console.log(check, "check-check-check");
    // };
    // if (data.name === "Instagram") {
    //   let checkSocket = w3cwebsocket(data.websocketTestListUrl);
    //   console.log(checkSocket);
    //   checkSocket.onopen = (check) => {
    //     console.log(check, "check-check-check");
    //   };
    // }
    if (!disabledButton) {
      axios
        .get(data.websocketTestUrl)
        .then((response) => {
          setDisableScrapping(!response?.data?.Success);
          if (data.name === "Instagram") {
            setDisableScrappingImport(!response?.data?.Success);
          }
        })
        .catch((error) => {
          setDisableScrapping(!error?.data?.Success);
          if (data.name === "Instagram") {
            setDisableScrappingImport(!error?.data?.Success);
          }
        });
    }
    if (data.name === "Instagram") {
      axios
        .get(data.websocketTestListUrl)
        .then((response) => {
          // setDisableScrappingImport(!response?.data?.Success);
        })
        .catch((error) => {
          // setDisableScrappingImport(!error?.data?.Success);
        });
    }
    // checkSocket.onopen = (check) => {
    //   console.log(check, "check-check-check");
    // };
    // checkSocket.onmessage = (message) => {
    //   console.log(message);
    // };
  };
  useEffect(() => {
    if (data) {
      scrapperCheckFn();
    }
  }, [data]);
  const theme = createTheme();
  const handleClose = () => {
    setupgradeModal(false);
  };
  const isOnlySpaces = /^\s*$/;
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      navigate("/");
    }
  }, [isLoading, isAuthenticated]);
  useEffect(() => {
    if (open === "PDF") {
      setDownloadPDFModal(true);
    } else if (open === "CSV") {
      setDownloadCSVModal(true);
    }
  }, [open]);
  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
    let arr = [];
    csvRows.map((i) => {
      const values = i.split(",");
      let str = values[0];
      if (typeof str === "string") {
        // let value = str.split('"')[1];
        // if (typeof value === "string") {
        arr.push(str);
        // } else {
        //   arr.push(JSON.parse(value));
        // }
      } else {
        arr.push(JSON.stringify(str));
      }
      // const obj = csvHeader.reduce((object, header, index) => {
      //   object[header] = JSON.parse(values[index]);
      //   return object;
      // }, {});
      // return obj;
    });
    let data = arr.join(",");
    setFilterText(data);
    setImportSearch(true);
    // onButtonClicked(data);
  };
  useEffect(() => {
    if (files !== "") {
      const fileReader = new FileReader();
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;

        csvFileToArray(csvOutput);
      };
      fileReader.readAsText(files);
    }
  }, [files]);

  const ids = tableData?.map((o) => {
    if (o == null) {
      return;
    } else {
      return o?.search;
    }
  });

  const PreviousListText = useMemo(
    () =>
      ids?.length
        ? ids[0]
          ? tableData?.filter((search, index) =>
              search !== null
                ? !ids?.includes(search?.search, index + 1)
                : false
            )
          : tableData
        : null,
    [tableData, ids]
  );

  const columns = data?.columns;
  // useEffect(() => console.log(tableData, "tableData"), [tableData]);
  let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  function connectToWebsocket(
    websocketUrl,
    websocketUrlListSearch,
    prevClient,
    setClient,
    setTableData,
    setPending,
    search,
    result_type,
    userData,
    setUserData,
    filterObj,
    filtering,
    setFilters,
    apiToken,
    importSearch,
    setImportSearch,
    setSearch,
    setDisabledButton
  ) {
    let count = userData?.user_metadata?.remainingCredits;
    let emailcheck = document.getElementById("email");
    let instagramcheck = document.getElementById("instagram");
    if (data.name === "YouTube") {
      let tiktokcheck = document.getElementById("tiktok");
      tiktokcheck.checked = false;
      setFilterObj({
        email: false,
        instagram: false,
        tiktok: false,
      });
    }
    if (
      data.name !== "Instagram" ||
      data.name !== "Twitter" ||
      data.name !== "SoundCloud"
    ) {
      if (instagramcheck?.checked) {
        instagramcheck.checked = false;
        setFilterObj({
          email: false,
          instagram: false,
          tiktok: false,
        });
      }
    }
    if (data.name !== "Spotify artists") {
      if (emailcheck.checked) {
        emailcheck.checked = false;
        setFilterObj({
          email: false,
          instagram: false,
          tiktok: false,
        });
      }
      // if (data.name !== "Instagram") {
    }
    //   let instagram = document.getElementById("instagram");
    //   if (instagram.checked) {
    //     instagram.checked = false;
    //   }
    // }
    setPending(true);
    prevClient?.close();
    const client = w3cwebsocket(
      importSearch ? websocketUrlListSearch : websocketUrl
    );
    client.onopen = () => {
      setClient(client);
      let filters =
        data.name === "Instagram"
          ? {
              min_followers: Number(filtering.min_followers),
            }
          : data.name === "Spotify playlists"
          ? {
              likes: Number(filtering.min_followers),
            }
          : data.name === "YouTube"
          ? {
              subscribers: Number(filtering.min_followers),
            }
          : data.name === "SoundCloud" ||
            data.name === "Tiktok" ||
            data.name === "Spotify artists" ||
            data.name === "Twitter"
          ? {
              followers: Number(filtering.min_followers),
            }
          : {
              followers: Number(filtering.min_followers),
              following: Number(filtering.min_following),
            };
      // let newSearch = importSearch?search:search;
      if (result_type === null) {
        client.send(
          JSON.stringify({
            user: userData?.user_id,
            search,
            filters: { ...filters },
          })
        );
      } else {
        client.send(
          JSON.stringify({
            user: userData?.user_id,
            result_type: result_type,
            search,
            filters: { ...filters },
          })
        );
      }
    };
    let arr = [];
    // if (data.name === "Spotify artists") {
    // setTimeout(() => {
    //   console.log("jkdflsssssssssssssssssssssssdgvjhjghfjghdjh");
    //   if (arr.length) {
    //   } else {
    //     toast.error("No data for the current search");
    //     console.log("No data for the current search");
    //     setImportSearch(false);
    //     setFilter({
    //       min_followers: 0,
    //       min_following: 0,
    //     });
    //     disconnectFromWebsocket(
    //       client,
    //       setDisabledButton,
    //       setSearch,
    //       setFilterText
    //     );
    //     getPreviousData(
    //       data.previousDataUrl(userInfo?.user_id),
    //       setTableData,
    //       setPending
    //     );
    //   }
    // }, 90000);
    // }
    client.onmessage = (message) => {
      let credits = 0;
      if (count >= credits) {
        const dataFromServer = JSON.parse(message.data);
        // console.log(typeof dataFromServer[dataFromServer?.length - 1]);
        arr = dataFromServer;
        let scrapData = dataFromServer[dataFromServer?.length - 1];
        // console.log(scrapData === null, scrapData);
        if (scrapData === null || !scrapData) {
          console.log("Nulsdjhsdjk");
        } else {
          if (typeof dataFromServer[dataFromServer?.length - 1] === "object") {
            let email = dataFromServer?.length
              ? dataFromServer[dataFromServer?.length - 1]?.data
              : [];
            if (data.name === "Instagram" || data.name === "Tiktok") {
              credits = credits + 1;
            }
            if (data.name === "YouTube") {
              if (email?.tiktok?.length) {
                credits = credits + 1;
              }
            }
            if (email?.email?.length) {
              credits = credits + 1;
            }
            if (data.name !== "Instagram") {
              if (email?.instagram?.length) {
                credits = credits + 1;
              }
            }
            setTableData(dataFromServer);
            setPending(false);
            console.log(credits, "creditsBeforeApi");
            if (credits !== 0) {
              count = count - credits;
              showCredits(count, setUserData, client, userData, apiToken);
            }
          }
        }
      } else {
        toast.error("You don't have enough credits");
        setPending(false);
        setTableData([]);
        setFilters({
          min_followers: 0,
          min_following: 0,
        });
        setImportSearch(false);
        disconnectFromWebsocket(
          client,
          setDisabledButton,
          setSearch,
          setFilterText
        );
        // getPreviousData(
        //   data?.previousDataUrl(userInfo?.user_id),
        //   setTableData,
        //   setPending
        // );
      }
    };
    client.onclose = (e) => {
      console.log(e, "e");
      setPending(false);
      if (!arr?.length) {
        // toast.error("No data for the current search");
      }
      setImportSearch(false);
      setFilter({
        min_followers: 0,
        min_following: 0,
      });
      disconnectFromWebsocket(
        client,
        setDisabledButton,
        setSearch,
        setFilterText
      );
      // getPreviousData(
      //   data?.previousDataUrl(userInfo?.user_id),
      //   setTableData,
      //   setPending
      // );
    };
    client.onerror = (e) => {
      toast.error("Under maintenance / Upgrade in progress");
      console.log(e);
      setPending(false);
      setImportSearch(false);
      setFilter({
        min_followers: 0,
        min_following: 0,
      });
      disconnectFromWebsocket(
        client,
        setDisabledButton,
        setSearch,
        setFilterText
      );
      // getPreviousData(
      //   data?.previousDataUrl(userInfo?.user_id),
      //   setTableData,
      //   setPending
      // );
    };
  }

  useEffect(() => {
    if (search !== null && search !== "") {
      // else {
      setFilterTextSelect("");
      setDisabledButton(true);
      setFilteredData([]);
      connectToWebsocket(
        data.websocketUrl,
        data.websocketUrlListSearch,
        client,
        setClient,
        setTableData,
        setPending,
        search,
        data.resultType,
        userData,
        setUserData,
        filterObj,
        filter,
        setFilter,
        apiToken,
        importSearch,
        setImportSearch,
        setSearch,
        setDisabledButton
      );
      // }
    }
  }, [search]);
  const onButtonClicked = useCallback(
    (search, isExpire) => {
      console.log(isExpire);
      if (!isOnlySpaces.test(search)) {
        if (userData?.user_metadata?.remainingCredits < 1) {
          setupgradeModal(true);
          toast.error("You don't have enough credits");
          document.getElementById("form-Submit-Search").reset();
        } else {
          setSearch(search);
          setSearchApi(true);
        }
      }
      // connectToWebsocket(
      //   data.websocketUrl,
      //   client,
      //   setClient,
      //   setTableData,
      //   setPending,
      //   search,
      //   data.resultType,
      //   userData,
      //   setUserData
      // );
    },
    [data, client]
  );
  const customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
      const aField = selector(rowA);
      const bField = selector(rowB);

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }
      return direction === customSorttext ? comparison * -1 : comparison;
    });
  };

  const filteredItemsArray = [];
  const getfilteredItems = () => {
    const filteredItemsArrayRowsData = tableData
      ?.filter((person) =>
        filterTextSelect === "" || filterTextSelect === "Filter"
          ? person?.search
          : person?.search === filterTextSelect
      )
      .map((item, index) => (filteredItemsArray[index] = item?.data));
    let filteredItems = filteredItemsArrayRowsData?.filter(
      (item) =>
        JSON.stringify(item)
          .toLowerCase()
          .indexOf(filterTextclick?.toLowerCase()) !== -1
    );
    const columns_data_for_export = columns?.map((d) => d.name);

    const rows_data_for_export = filteredItems?.map((d1) =>
      columns?.map((d2) => d2?.value).map((d3) => d1[d3])
    );
    setRows_data_for_export(rows_data_for_export);
    setColumns_data_for_export(columns_data_for_export);
    setFilteredOldData(filteredItems);
    console.log(filteredItems);
    setResetPaginationToggle(!resetPaginationToggle);
  };
  useEffect(() => {
    getfilteredItems();
  }, [tableData, filterTextSelect]);
  useEffect(() => {
    emailFilter();
  }, [
    filterObj?.email,
    filterObj?.instagram,
    filterObj?.tiktok,
    filteredOldData,
  ]);
  const emailFilter = () => {
    let filter = [];
    if (filterObj?.email || filterObj?.instagram || filterObj?.tiktok) {
      if (filterObj?.instagram && filterObj?.email && filterObj?.tiktok) {
        filter = filteredOldData.filter(
          (ev) =>
            ev?.email?.length !== 0 ||
            ev?.instagram?.length !== 0 ||
            ev?.tiktok?.length !== 0
        );
      } else if (filterObj?.instagram && filterObj?.email) {
        filter = filteredOldData.filter(
          (ev) => ev?.email?.length !== 0 || ev?.instagram?.length !== 0
        );
      } else if (filterObj?.tiktok && filterObj?.email) {
        filter = filteredOldData.filter(
          (ev) => ev?.email?.length !== 0 || ev?.tiktok?.length !== 0
        );
      } else if (filterObj?.email) {
        if (data.name === "SoundCloud") {
          filter = filteredOldData.filter((ev) => ev?.email?.length !== 0);
        } else {
          filter = filteredOldData.filter((ev) => ev?.email?.length !== 0);
        }
      } else if (filterObj?.instagram) {
        filter = filteredOldData.filter((ev) => ev?.instagram?.length !== 0);
      } else if (filterObj?.tiktok) {
        filter = filteredOldData.filter((ev) => ev?.tiktok?.length !== 0);
      }
      // setFilteredData(filter);
      // setFilteredSearchData(filter);
    }
    //  else if (filterObj?.email) {
    //   if (data.name === "SoundCloud") {
    //     filter = filteredOldData.filter((ev) => ev?.email?.length !== 0);
    //   } else {
    //     filter = filteredOldData.filter((ev) => ev?.email?.length !== 0);
    //   }
    //   // setFilteredData(filter);
    //   // setFilteredSearchData(filter);
    // } else if (filterObj?.instagram) {
    //   filter = filteredOldData.filter((ev) => ev?.instagram?.length !== 0);
    // } else if (filterObj?.tiktok) {
    //   filter = filteredOldData.filter((ev) => ev?.tiktok?.length !== 0);
    // }
    else {
      filter = filteredOldData;
    }
    const columns_data_for_export = columns?.map((d) => d.name);

    const rows_data_for_export = filter?.map((d1) =>
      columns?.map((d2) => d2?.value).map((d3) => d1[d3])
    );
    setRows_data_for_export(rows_data_for_export);
    setColumns_data_for_export(columns_data_for_export);

    setFilteredData(filter);
    setFilteredSearchData(filter);
  };
  const FilterValueOnClick = (event) => {
    if (event.target.value === "") {
      setcustomSortcount(3);
      setcustomSorttext("asc");
    } else if (event.target.value === "By_Date") {
      setcustomSortcount(2);
      setcustomSorttext("desc");
    } else if (event.target.value === "By_Group") {
      setcustomSortcount(2);
      setcustomSorttext("asc");
    } else if (event.target.value === "By_Ascending_Order") {
      setcustomSortcount(2);
      setcustomSorttext("desc");
    } else if (event.target.value === "By_Descending_Order") {
      setcustomSortcount(2);
      setcustomSorttext("asc");
    }
  };

  const onFilterOnClick = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setFilterText(e.target.value);
      setImportSearch(false);
      setFilterTextclick("");
    } else {
      setFilterText(e.target.value);
    }
  };

  const PreviousListFilterOnChange = (e) => {
    setResetPaginationToggle(!resetPaginationToggle);
    // let emailcheck = document.getElementById("email");
    // if (emailcheck.checked) {
    //   emailcheck.checked = false;
    //   setFilterObj({
    //     email: false,
    //     instagram: false,
    //   });
    // }
    if (e === "Filter") {
      setFilterTextSelect(e);
    } else {
      if (e === "") {
        if (searchApi) {
          setDataToggle(!dataToggle);
          setSearchApi(false);
        }
        setFilterTextSelect(e);
      } else {
        setFilterTextSelect(e);
      }
    }
  };
  useEffect(() => {
    if (!isOnlySpaces.test(searchScrappedData)) {
      const filter = filteredSearchData.filter(
        (item) =>
          item?.name.toLowerCase().includes(searchScrappedData.toLowerCase())
        // ||item?.url.toLowerCase().includes(searchScrappedData.toLowerCase())
        // item?.email.toLowerCase().includes(searchScrappedData.toLowerCase())
        // item.artist_name
        //   .toLowerCase()
        //   .includes(searchScrappedData.toLowerCase()) ||
        // item.followers
        //   .toLowerCase()
        //   .includes(searchScrappedData.toLowerCase()) ||
        // item.link.toLowerCase().includes(searchScrappedData.toLowerCase()) ||
        // item.name.toLowerCase().includes(searchScrappedData.toLowerCase())
      );
      setFilteredData(filter);
    } else {
      // setFilteredData(filteredSearchData);
    }
  }, [searchScrappedData, filteredData]);

  const download_pdf = () => {
    if (!disabledButton) {
      // let str =data.name===""?"p", "mm", [1500, 1500]:""
      const doc =
        data.name === "YouTube"
          ? new jsPDF("p", "mm", [700, 700], {
              orientation: "landscape",
              // floatPrecision: true,
            })
          : new jsPDF("p", "mm", [500, 500], {
              orientation: "landscape",
              floatPrecision: true,
            });
      const temp_rows = filteredOldData?.map((d1) =>
        columns?.map((d2) => d2?.value).map((d3) => d1[d3])
      );
      // let obj = {
      //   0: { cellWidth: 100 },
      //   1: { cellWidth: 40 },
      //   2: { cellWidth: 80 },
      //   // etc
      // };

      let obj = {};
      // let pdfWidth = 0;
      for (let i = 0; i < filteredOldData?.length; i++) {
        obj[i] = { cellWidth: 50 };
        // pdfWidth = pdfWidth + 50;
      }
      // let obj = {};
      // for (let i = 0; i < filteredData?.length; i++) {
      //   // obj = {
      //   //   i: {},
      //   // };
      //   // obj.i = { cellWidth: 50 }; // etc
      //   console.log(obj);
      // }
      doc.autoTable({
        head: [columns_data_for_export],
        body: temp_rows,
        columnStyles: obj,
      });

      doc.save(
        `${data.name}-${
          filterTextSelect === "" || filterTextSelect === "Filter"
            ? "select-all"
            : filterTextSelect
        }-${new Date()}.pdf`
      );
      setDownloadPDFModal(false);
    }
  };
  const onChangeRoute = () => {
    console.log(data.name.toLowerCase());
    navigate(
      `/subscription-plan?redirect=${data.name.toLowerCase()}-dashboard${
        upgradeAfter !== "" ? `&open=${upgradeAfter}` : ""
      }`
    );
  };
  useEffect(() => {
    return () => {
      if (client?.readyState === 1) {
        // Cleanup event listener on component unmount
        setImportSearch(false);
        disconnectFromWebsocket(
          client,
          setDisabledButton,
          setSearch,
          setFilterText,
          true
        );
      }
    };
  }, [client]);

  {
    /* <DataTable
                title="Example Table"
                columns={columns}
                data={data}
                pagination
                paginationComponent={CustomPagination}
                paginationPerPage={5} // Specify the number of items per page
              /> */
  }
  // const memo = useMemo(() => {
  //   return (
  //     <DataTable
  //       columns={columns}
  //       data={filteredData}
  //       noDataComponent={
  //         <div className="nothing-to-show">
  //           <h6>Nothing to show yet</h6>
  //           <img src="./Assets/Images/components/sendAnimation.png" alt="" />
  //         </div>
  //       }
  //       pagination={!disabledButton}
  //       // paginationComponent={CustomPagination}
  //       paginationResetDefaultPage={filterObj?.email || disabledButton}
  //       defaultSortFieldId={customSortcount}
  //       sortFunction={customSort}
  //       progressPending={pending}
  //       progressComponent={
  //         <Processing
  //           extraClass={true}
  //           header={"Processing"}
  //           // header2={"Lorem ipsum dolor sit amet"}
  //           text={
  //             "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna diam et id vel lorem interdum sed."
  //           }
  //         />
  //       }
  //     />
  //   );
  // }, [filteredData, disabledButton, pending, columns, customSortcount]);
  return (
    <>
      <HeaderUI
        userData={userData}
        setApiCall={setApiCall}
        apiCall={apiCall}
        setUserData={setUserData}
      />
      <div className="margin-left-right">
        <BreadCrumb page={data.name} />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 container-fuild-margin">
            <h1>Dashboard</h1>
          </div>
        </div>

        <FilterComponent
          disableScrapping={disableScrapping}
          disableScrappingImport={disableScrappingImport}
          data={data}
          onFilter={onFilterOnClick}
          filterText={filterText}
          setFiles={setFiles}
          setSearch={setSearch}
          setFilterText={setFilterText}
          filterTextclick={onButtonClicked}
          isExpire={isExpire}
          client={client}
          disabledButton={disableSocket || disabledButton}
          disconnectFromWebsocket={disconnectFromWebsocket}
          setDisabledButton={setDisabledButton}
          files={files}
          setFilterObj={setFilter}
          filterObj={filter}
          platform={data.name}
          userData={userData}
          importSearch={importSearch}
          setImportSearch={setImportSearch}
        />

        <div className="row container-fuild-margin">
          <span className="input-text-check-box">
            <input
              type="text"
              placeholder="Search"
              className="mass-input"
              value={searchScrappedData}
              disabled={disabledButton}
              onChange={(ev) => {
                if (isOnlySpaces.test(ev.target.value)) {
                  setSearchScrappedData("");
                  // toast.error("")
                  getfilteredItems();
                } else if (ev.target.value === "") {
                  setSearchScrappedData(ev.target.value);
                  getfilteredItems();
                } else {
                  setSearchScrappedData(ev.target.value);
                }
              }}
              id="input-filed"
            />
            <div className="checkBoxes">
              {data.name === "Spotify artists" ? null : (
                <div
                  className="form-group"
                  style={disabledButton ? { opacity: 0.5 } : { opacity: 1 }}
                >
                  <input
                    type="checkbox"
                    id="email"
                    className="checkBox"
                    name="remember"
                    disabled={disabledButton}
                    value="1"
                    onClick={() => {
                      if (!disabledButton) {
                        setFilterObj({
                          ...filterObj,
                          email: !filterObj?.email,
                        });
                      }
                    }}
                  />
                  <label for="email">Show results with emails</label>
                </div>
              )}
              {data.name === "Instagram" ||
              data.name === "Twitter" ||
              data.name === "SoundCloud" ? null : (
                <div
                  className="form-group"
                  style={disabledButton ? { opacity: 0.5 } : { opacity: 1 }}
                >
                  <input
                    onClick={() => {
                      if (!disabledButton) {
                        setFilterObj({
                          ...filterObj,
                          instagram: !filterObj?.instagram,
                        });
                      }
                    }}
                    disabled={disabledButton}
                    type="checkbox"
                    id="instagram"
                    className="checkBox"
                    name="terms_and_conditions"
                    value="1"
                  />
                  <label for="instagram">
                    Show results with instagram profiles
                  </label>
                </div>
              )}
              {data.name === "YouTube" ? (
                <div
                  className="form-group"
                  style={disabledButton ? { opacity: 0.5 } : { opacity: 1 }}
                >
                  <input
                    onClick={() => {
                      if (!disabledButton) {
                        setFilterObj({
                          ...filterObj,
                          tiktok: !filterObj?.tiktok,
                        });
                      }
                    }}
                    disabled={disabledButton}
                    type="checkbox"
                    id="tiktok"
                    className="checkBox"
                    name="terms_and_conditions"
                    value="1"
                  />
                  <label for="tiktok">Show results with Tiktok profiles</label>
                </div>
              ) : null}
            </div>
          </span>
          <div className="col-lg-12 col-md-12 col-sm-12 container-padding">
            <div className={"filter-div"}>
              {/* {data.name === "Instagram" ? null : ( */}
              {/* <Form.Select
                className="filter-select"
                onChange={PreviousListFilterOnChange}
                defaultValue={"filter"}
                defaultChecked={true}
                disabled={disabledButton}
              >
                <option disabled={true} value={"filter"}>
                  filter
                </option>
                <option value={""}>All</option>
                {data.name === "Instagram"
                  ? null
                  : PreviousListText.map((item, index) => (
                      <option key={index} value={item.search}>
                        {item.search}
                      </option>
                    ))}
              </Form.Select> */}
              <SelectComponent
                PreviousListText={PreviousListText}
                PreviousListFilterOnChange={PreviousListFilterOnChange}
                disabled={disabledButton}
              />
              {disabledButton ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    <div className={`loader-spinner loader-small`}>
                      <div className="inner-background"></div>
                      <div className="inner-circle"></div>
                      <div className="inner-circle-2"></div>
                    </div>{" "}
                    Your results are loading
                  </div>
                  {/* <Loader/> */}
                  <ButtonComponent
                    onClick={(e) => {
                      if (!filteredData?.length) {
                        toast.error("No data for the current search");
                      }
                      setFilter({
                        min_followers: 0,
                        min_following: 0,
                      });
                      setImportSearch(false);
                      // getPreviousData(
                      //   data?.previousDataUrl(userInfo?.user_id),
                      //   setTableData,
                      //   setPending
                      // );
                      disconnectFromWebsocket(
                        client,
                        setDisabledButton,
                        setSearch,
                        setFilterText
                      );
                    }}
                    // disabled={disabledButton}
                    btnclass="stop-socket"
                    text={"Stop"}
                    style={{
                      height: "50px",
                      whiteSpace: "nowrap",
                      borderRadius: "10px",
                      width: "150px",
                      marginLeft: "30px",
                      border: "none",
                    }}
                  />
                </div>
              ) : null}

              {/* <Form.Select
                className="filter-select"
                onChange={FilterValueOnClick}
              >
                <option value={""}>Filter</option>
                <option value={"By_Date"}>By Date</option>
                <option value={"By_Group"}>By Group</option>
                <option value={"By_Ascending_Order"}>By Ascending Order</option>
                <option value={"By_Descending_Order"}>
                  By Descending Order
                </option>
              </Form.Select> */}
            </div>
            <DataTable
              columns={columns}
              data={filteredData}
              noDataComponent={
                <div className="nothing-to-show">
                  <img
                    style={{ height: "360px" }}
                    src="./Assets/Images/components/sendAnimation.svg"
                    alt=""
                  />
                </div>
              }
              pagination={!disabledButton}
              // onChangePage={handlePageChange}
              // onChangeRowsPerPage={handleItemsPerPageChange}
              // paginationTotalRows={totalItems}
              // paginationPerPage={itemsPerPage}
              // paginationRowsPerPageOptions={[10, 20, 30]}
              // paginationComponent={CustomPagination}
              paginationResetDefaultPage={
                resetPaginationToggle || filterObj?.email || disabledButton
              }
              defaultSortFieldId={customSortcount}
              sortFunction={customSort}
              // sortServer={true}
              progressPending={pending}
              progressComponent={
                <Processing
                  extraClass={true}
                  header={"Processing"}
                  // header2={"Lorem ipsum dolor sit amet"}
                  text={
                    data.name === "Twitter"
                      ? "It usually takes 60 to 90 seconds to generate data through Twitter."
                      : "Please wait while we are processing your request"
                  }
                />
              }
            />
            <div className="csv-pdf-btns mt-4">
              <button
                disabled={filteredData?.length ? disabledButton : true}
                className="flaot-import-button flaot-border-radius float-button btn-background download-btn download-btn-width"
                // onClick={download_pdf}
                onClick={() => {
                  if (userData?.user_metadata?.subscriptionPlan === "Free") {
                    setupgradeModal(true);
                    setupgradeAfter("PDF");
                  } else if (!disabledButton) {
                    setDownloadPDFModal(true);
                  }
                }}
                style={{
                  opacity: disabledButton ? 0.5 : 1,
                  position: "relative",
                }}
              >
                Download PDF file
                {userData?.user_metadata?.subscriptionPlan === "Free" && (
                  <img
                    src="./Assets/Images/premium.png"
                    className="download-premium"
                  />
                )}
              </button>
              <button
                disabled={filteredData?.length ? disabledButton : true}
                className="flaot-import-button flaot-border-radius float-button btn-background download-btn download-btn-width"
                // onClick={download_pdf}
                onClick={() => {
                  if (userData?.user_metadata?.subscriptionPlan === "Free") {
                    setupgradeAfter("CSV");
                    setupgradeModal(true);
                  } else if (!disabledButton && filteredData?.length) {
                    setDownloadCSVModal(true);
                  }
                }}
                style={{
                  opacity: disabledButton ? 0.5 : 1,
                  position: "relative",
                }}
              >
                Download CSV file
                {userData?.user_metadata?.subscriptionPlan === "Free" && (
                  <img
                    src="./Assets/Images/premium.png"
                    className="download-premium"
                  />
                )}
              </button>
            </div>
            {/* </>
            )} */}

            {/* <CSVLink
                  onClick={() => {
                    return !disabledButton;
                  }}
                  style={
                    disabledButton
                      ? {
                          background: "grey",
                          textDecoration: "none",
                          marginTop: "20px",
                          cursor: "unset",
                        }
                      : {
                          textDecoration: "none",
                          marginTop: "20px",
                          background: "#01c38d",
                        }
                  }
                  className="flaot-import-button flaot-border-radius float-button"
                  data={rows_data_for_export}
                  // new Date().toDateString()
                  headers={columns_data_for_export}
                  filename={`${data.name}-${
                    filterTextSelect === "" || filterTextSelect === "filter"
                      ? "select-all"
                      : filterTextSelect
                  }-${new Date()}.csv`}
                >
                  Download CSV file
                </CSVLink> */}
          </div>
        </div>
      </div>
      <DownloadModal
        handleClose={() => {
          setDownloadCSVModal(false);
          navigate(window.location.pathname);
        }}
        csvFile={true}
        disabledButton={disabledButton}
        rows_data_for_export={rows_data_for_export}
        columns_data_for_export={columns_data_for_export}
        open={downloadCSVModal}
        data={data}
        descriptions={"Click on below button to download CSV file"}
        filterTextSelect={filterTextSelect}
      />
      <DownloadModal
        handleClose={() => {
          setDownloadPDFModal(false);
          navigate(window.location.pathname);
        }}
        csvFile={false}
        descriptions={"Click on below button to download PDF file"}
        open={downloadPDFModal}
        download_pdf={download_pdf}
      />
      <UpdateModal
        show={upgradeModal}
        setupgradeModal={setupgradeModal}
        handleClose={handleClose}
        onNext={onChangeRoute}
      />
      {/* <UpgradeModal
disabledButton={disabledButton}
        src="/Assets/Images/components/Sendmsg.png"
        socailIcon={"instragram"}
        premium={
          userData?.user_metadata?.subscriptionPlan === "Free" ||
          !userData?.user_metadata?.subscriptionPlan
            ? true
            : false
        }
        userData={userData}
        setUserData={setUserData}
      /> */}
      {/* <UpgradeModal
disabledButton={disabledButton}
        src="./Assets/Images/components/Sendmsg.png"
        socailIcon={"instragram"}
        premium={
          userData?.user_metadata?.subscriptionPlan === "Free" ||
          !userData?.user_metadata?.subscriptionPlan
            ? true
            : false
        }
        userData={userData}
        setUserData={setUserData}
      /> */}
      {/* <FooterComponent /> */}
    </>
  );
}
