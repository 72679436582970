import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ButtonComponent from "../Components/buttonComponent";
import SubscriptionCards from "../Components/subscriptionCards";
import ImageComponent from "../Components/imageComponent";
import StripeComponentModal from "../Components/StripeComponentModal";
import CancelModal from "../Components/CancelModal";
import UpgradeModal from "../Components/UpgradeModal";
import { Tooltip } from "antd";

function CurrentPlan({
  userData,
  navigation,
  apiCall,
  setApiCall,
  day,
  hours,
  mint,
  seconds,
  endDate,
  onSubscribtionEnd,
  cancelLoader,
  setUserData,
  intervalTime,
  subscription,
  packages,
}) {
  // console.log(userData);
  const [expiryDate, setExpiryDate] = useState("");
  const [subscriptionCurrency, setSubscriptionCurrency] = useState(0);
  const [subscriptionCurrencySymbol, setSubscriptionCurrencySymbol] =
    useState("€");
  useEffect(() => {
    if (subscription?.length) {
      let date = new Date(subscription[0]?.current_period_end * 1000);
      setExpiryDate(
        `${date?.getDate()}/${date?.getMonth() + 1}/${date?.getFullYear()}`
      );
    }
  }, [subscription]);
  useEffect(() => {
    if (subscription?.length && packages?.length) {
      let filter = packages?.filter(
        (ev) => ev?.id === subscription[0]?.plan?.id
      )[0];
      let currency = subscription[0]?.currency;
      let prices =
        currency === "usd"
          ? filter?.currency_options?.usd?.unit_amount / 100
          : currency === "gbp"
          ? filter?.currency_options?.gbp?.unit_amount / 100
          : filter?.currency_options?.eur?.unit_amount / 100;
      setSubscriptionCurrency(
        intervalTime === "year" ? Number(prices / 12).toFixed(2) : prices
      );
      setSubscriptionCurrencySymbol(
        subscription[0]?.currency === "usd"
          ? "$"
          : subscription[0]?.currency === "gbp"
          ? "£"
          : "€"
      );
    }
  }, [subscription, packages]);
  return (
    <div className="current-Plan">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="Current-header-text">Current plan</div>
        {/* <div className="subDetails">
          {"Subscription Ends at " + new Date(endDate)}
        </div> */}
      </div>
      <div className="inner-current-Plan">
        <div className="price-Plan">
          {
            userData?.user_metadata?.subscriptionPlan === "Starter" ? (
              <div className="subscription-profile">
                <div
                  className="card-header"
                  style={{
                    borderRadius: "10px 10px 0px 0px",
                    padding: "12px 0px",
                  }}
                >
                  <ButtonComponent
                    text="Starter"
                    btnclass="btn-background mb-2"
                    style={{
                      height: "38px",
                      width: "151px",
                      whiteSpace: "nowrap",
                      borderRadius: "10px",
                      cursor: "auto",
                    }}
                  />
                  <img src="./Assets/Images/gift.png" alt="gift" />
                </div>
                <div className="card-body" style={{ padding: "16px" }}>
                  <div
                    className="profile-subscription-header-parent"
                    style={{
                      flexDirection: "column",
                      alignItems: "baseline",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className="profile-subscription-header">
                        {/* {intervalTime === "year" ? "€100" : "€25"} */}
                        {subscriptionCurrencySymbol}
                        {subscriptionCurrency}
                      </div>
                      / Month
                    </div>
                    <p>
                      {intervalTime === "year"
                        ? "Billed annually"
                        : "Billed monthly"}
                    </p>
                  </div>
                  <p className="subscriptionPlans-header">Includes:</p>
                  <div className="includes-list">
                    {[
                      `${
                        intervalTime === "year"
                          ? process.env.REACT_APP_STRIPE_CREDITS_STARTER_Yearly
                          : process.env.REACT_APP_STRIPE_CREDITS_STARTER_MONTHLY
                      } credits per ${intervalTime}`,
                      "Instagram search",
                      "Instagram URL imports feature",
                      "Spotify artists search",
                      "Spotify playlists search",
                      "SoundCloud search",
                      "TikTok search",
                      "Youtube search",
                      "Twitter search",
                      "Export of results",
                      "Priority support",
                      "Direct message",
                    ].map((e, i) => {
                      let no = 10;
                      return (
                        <div
                          className={`subscriptionPlans-list ${
                            no !== 0 ? (no <= i ? "" : "") : ""
                          }`}
                        >
                          {no !== 0 ? (
                            no <= i ? (
                              <div className="img-square">❌</div>
                            ) : (
                              <ImageComponent
                                className="img-square"
                                src="/Assets/Images/tick-square.png"
                              />
                            )
                          ) : (
                            <ImageComponent
                              className="img-square"
                              src="/Assets/Images/tick-square.png"
                            />
                          )}

                          {i === 0 ? (
                            <Tooltip
                              rootClassName="tooltip-credit-details"
                              // className="tooltip-credit-details"
                              placement="bottomLeft"
                              title={`Credits are used to generate the contact information of your desired leads. It costs 1 credit for each Email address, Instagram link, and TikTok link.`}
                              arrow={true}
                            >
                              {e}
                            </Tooltip>
                          ) : (
                            e
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : userData?.user_metadata?.subscriptionPlan === "Medium" ? (
              <div className="subscription-profile">
                <div
                  className="card-header"
                  style={{
                    borderRadius: "10px 10px 0px 0px",
                    padding: "12px 0px",
                  }}
                >
                  <ButtonComponent
                    text="Medium"
                    btnclass="btn-background mb-2"
                    style={{
                      height: "38px",
                      width: "151px",
                      whiteSpace: "nowrap",
                      borderRadius: "10px",
                      cursor: "auto",
                    }}
                  />
                  <img src="/Assets/Images/premium-product.png" alt="gift" />
                </div>
                <div className="card-body" style={{ padding: "16px" }}>
                  <div
                    className="profile-subscription-header-parent"
                    style={{
                      flexDirection: "column",
                      alignItems: "baseline",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className="profile-subscription-header">
                        {/* {intervalTime === "year" ? "€100" : "€25"} */}
                        {subscriptionCurrencySymbol}
                        {subscriptionCurrency}
                      </div>
                      / Month
                    </div>
                    <p>
                      {intervalTime === "year"
                        ? "Billed annually"
                        : "Billed monthly"}
                    </p>
                  </div>
                  {/* <p className="profile-subscription-header-para">
                    {intervalTime === "year"
                      ? "€200 per Year billed"
                      : "€100 per month billed"}
                  </p> */}
                  <p className="subscriptionPlans-header">Includes:</p>
                  <div className="includes-list">
                    {[
                      `${
                        intervalTime === "year"
                          ? process.env.REACT_APP_STRIPE_CREDITS_MEDIUM_Yearly
                          : process.env.REACT_APP_STRIPE_CREDITS_MEDIUM_MONTHLY
                      } credits per ${intervalTime}`,
                      "Instagram search",
                      "Instagram URL imports feature",
                      "Spotify artists search",
                      "Spotify playlists search",
                      "SoundCloud search",
                      "TikTok search",
                      "Youtube search",
                      "Twitter search",
                      "Export of results",
                      "Priority support",
                      "Direct message",
                    ].map((e, i) => {
                      let no = 11;
                      return (
                        <div className="subscriptionPlans-list">
                          {no !== 0 ? (
                            no <= i ? (
                              <div className="img-square">❌</div>
                            ) : (
                              <ImageComponent
                                className="img-square"
                                src="/Assets/Images/tick-square.png"
                              />
                            )
                          ) : (
                            <ImageComponent
                              className="img-square"
                              src="/Assets/Images/tick-square.png"
                            />
                          )}
                          {i === 0 ? (
                            <Tooltip
                              rootClassName="tooltip-credit-details"
                              // className="tooltip-credit-details"
                              placement="bottomLeft"
                              title={`Credits are used to generate the contact information of your desired leads. It costs 1 credit for each Email address, Instagram link, and TikTok link.`}
                              arrow={true}
                            >
                              {e}
                            </Tooltip>
                          ) : (
                            e
                          )}
                          {/* {e === "Priority support"
                          ,
                          z && <sub>(Coming Soon)</sub>} */}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : // <SubscriptionCards
            //   navigation={navigation}
            //   plansText={"Medium"}
            //   // url={"/Assets/Images/premium-product.png"}
            //   headertext={"€100"}
            //   headerpara={"€100 per month"}
            //   SubscriptionText={"Buy Now"}
            //   subscriptionPlans={[
            //     "User of our features",
            //     "Any features to use",
            //     `10000 credits per ${intervalTime}`,
            //     "Export of results",
            //     "Priority support"
            //     "Instagram search",
            //     "Instagram URL imports feature",
            //     "Spotify artists search",
            //     "Spotify playlists search",
            //     "Soundcloud search",
            //     "TikTok search",
            //     "Youtube search", "Twitter search",
            //   ]}
            //   confirmPayment={true}
            // />
            userData?.user_metadata?.subscriptionPlan === "Pro" ? (
              <div className="subscription-profile">
                <div
                  className="card-header"
                  style={{
                    borderRadius: "10px 10px 0px 0px",
                    padding: "12px 0px",
                  }}
                >
                  <ButtonComponent
                    text="Pro"
                    btnclass="btn-background mb-2"
                    style={{
                      height: "38px",
                      width: "151px",
                      whiteSpace: "nowrap",
                      borderRadius: "10px",
                      cursor: "auto",
                    }}
                  />
                  <img src="/Assets/Images/premium-product.png" alt="gift" />
                </div>
                <div className="card-body" style={{ padding: "16px" }}>
                  <div
                    className="profile-subscription-header-parent"
                    style={{
                      flexDirection: "column",
                      alignItems: "baseline",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className="profile-subscription-header">
                        {/* {intervalTime === "year" ? "€100" : "€25"} */}
                        {subscriptionCurrencySymbol}
                        {subscriptionCurrency}
                      </div>
                      / Month
                    </div>
                    <p>
                      {intervalTime === "year"
                        ? "Billed annually"
                        : "Billed monthly"}
                    </p>
                  </div>

                  <p className="subscriptionPlans-header">Includes:</p>
                  <div className="includes-list">
                    {[
                      `${
                        intervalTime === "year"
                          ? process.env.REACT_APP_STRIPE_CREDITS_PRO_Yearly
                          : process.env.REACT_APP_STRIPE_CREDITS_PRO_MONTHLY
                      } credits per ${intervalTime}`,
                      "Instagram search",
                      "Instagram URL imports feature",
                      "Spotify artists search",
                      "Spotify playlists search",
                      "SoundCloud search",
                      "TikTok search",
                      "Youtube search",
                      "Twitter search",
                      "Export of results",
                      "Priority support",
                      "Direct message",
                    ].map((e, i) => {
                      return (
                        <div className="subscriptionPlans-list">
                          <ImageComponent
                            className="img-square"
                            src="/Assets/Images/tick-square.png"
                          />
                          {i === 0 ? (
                            <Tooltip
                              rootClassName="tooltip-credit-details"
                              // className="tooltip-credit-details"
                              placement="bottomLeft"
                              title={`Credits are used to generate the contact information of your desired leads. It costs 1 credit for each Email address, Instagram link, and TikTok link.`}
                              arrow={true}
                            >
                              {e}
                            </Tooltip>
                          ) : (
                            e
                          )}
                          {/* {e === "Priority support" && <sub>(Coming Soon)</sub>} */}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : // <SubscriptionCards
            //   navigation={navigation}
            //   plansText={"Pro"}
            //   // url={"/Assets/Images/premium-product.png"}
            //   headertext={"€200"}
            //   headerpara={"€200 per month"}
            //   SubscriptionText={"Buy Now"}
            //   subscriptionPlans={[
            //     "User of our features",
            //     "Any features to use",
            //     `50000 credits per ${intervalTime}`,
            //     "Export of results",
            //     "Instagram search",
            //     "Instagram URL imports feature",
            //     "Spotify artists search",
            //     "Spotify playlists search",
            //     "Soundcloud search",
            //     "TikTok search",
            //     "Youtube search", "Twitter search",
            //     "Priority support",
            //   ]}
            //   confirmPayment={true}
            // />
            userData?.user_metadata?.subscriptionPlan === "Free" ? (
              <div className="subscription-profile">
                <div
                  className="card-header"
                  style={{
                    borderRadius: "10px 10px 0px 0px",
                    padding: "12px 0px",
                  }}
                >
                  <ButtonComponent
                    text="Free"
                    btnclass="btn-background mb-2"
                    style={{
                      height: "38px",
                      width: "151px",
                      whiteSpace: "nowrap",
                      borderRadius: "10px",
                      cursor: "auto",
                    }}
                  />
                  <img src="/Assets/Images/premium-product.png" alt="gift" />
                </div>
                <div className="card-body" style={{ padding: "16px" }}>
                  <div className="profile-subscription-header">Free</div>
                  <p className="subscriptionPlans-header">Includes:</p>
                  <div className="includes-list">
                    {[
                      `250 credits`,
                      "Instagram search",
                      "Instagram URL imports feature",
                      "Spotify artists search",
                      "Spotify playlists search",
                      "SoundCloud search",
                      "TikTok search",
                      "Youtube search",
                      "Twitter search",
                      "Export of results",
                      "Priority support",
                      "Direct message",
                    ].map((e, i) => {
                      let no = 9;
                      return (
                        <div
                          className={`subscriptionPlans-list ${
                            no !== 0 ? (no <= i ? "" : "") : ""
                          }`}
                        >
                          {no !== 0 ? (
                            no <= i ? (
                              <div className="img-square">❌</div>
                            ) : (
                              <ImageComponent
                                className="img-square"
                                src="/Assets/Images/tick-square.png"
                              />
                            )
                          ) : (
                            <ImageComponent
                              className="img-square"
                              src="/Assets/Images/tick-square.png"
                            />
                          )}
                          {i === 0 ? (
                            <Tooltip
                              rootClassName="tooltip-credit-details"
                              // className="tooltip-credit-details"
                              placement="bottomLeft"
                              title={`Credits are used to generate the contact information of your desired leads. It costs 1 credit for each Email address, Instagram link, and TikTok link.`}
                              arrow={true}
                            >
                              {e}
                            </Tooltip>
                          ) : (
                            e
                          )}
                        </div>
                      );
                    })}
                    {/* <p className="see-details">See details</p> */}
                  </div>
                </div>
              </div>
            ) : // <SubscriptionCards
            //   navigation={navigation}
            //   confirmPayment={true}
            //   plansText={"Free"}
            //   // url={"/Assets/Images/puzzle.png"}
            //   headertext={"Free"}
            //   headerpara={"Free for the first month"}
            //   SubscriptionText={"Start for free"}
            //   subscriptionPlans={[
            //     "Use of our features",
            //     "Any platform to use",
            //     `250 credits per ${intervalTime}  `,
            //     "Instagram search",
            //     "Instagram URL imports feature",
            //     "Spotify artists search",
            //     "Spotify playlists search",
            //     "Soundcloud search",
            //     "TikTok search",
            //     "Youtube search", "Twitter search",
            //   ]}
            // />
            null
            // (
            //   <div className="subscription-profile">
            //     <div
            //       className="card-header"
            //       style={{
            //         borderRadius: "10px 10px 0px 0px",
            //         padding: "12px 0px",
            //       }}
            //     >
            //       <ButtonComponent
            //         text="STARTER"
            //         btnclass="btn-background mb-2"
            //         style={{
            //           height: "38px",
            //           width: "151px",
            //           whiteSpace: "nowrap",
            //           borderRadius: "10px",
            //         }}
            //       />
            //       <img src="./Assets/Images/gift.png" alt="gift" />
            //     </div>
            //     <div className="card-body" style={{ padding: "16px" }}>
            //       <div className="profile-subscription-header">€25</div>

            //       <p className="profile-subscription-header-para">
            //         25 per month billed
            //       </p>
            //       <p className="subscriptionPlans-header">Includes:</p>
            //       <div className="includes-list">
            //         <div className="subscriptionPlans-list">
            //           <ImageComponent
            //             className="img-square"
            //             src="/Assets/Images/tick-square.png"
            //           />
            //           Use of our features
            //         </div>
            //         <div className="subscriptionPlans-list">
            //           <ImageComponent
            //             className="img-square"
            //             src="/Assets/Images/tick-square.png"
            //           />
            //           Use of our features
            //         </div>
            //         <div className="subscriptionPlans-list">
            //           <ImageComponent
            //             className="img-square"
            //             src="/Assets/Images/tick-square.png"
            //           />
            //           Use of our features
            //         </div>
            //         <div className="subscriptionPlans-list">
            //           <ImageComponent
            //             className="img-square"
            //             src="/Assets/Images/tick-square.png"
            //           />
            //           Use of our features
            //         </div>
            //         <p className="see-details">See details</p>
            //       </div>
            //     </div>
            //   </div>
            // )
          }
        </div>
        <div className="remaining-Credits">
          <div className="inner-remaining-Credits margin-bottom">
            <h6 className="Credits-header-text">Remaining credits</h6>
            <div className="inner-Remaining">
              <div>{userData?.user_metadata?.remainingCredits || 0}/</div>
              <h5>{userData?.user_metadata?.credits || 0}</h5>
            </div>
          </div>
          {userData?.user_metadata?.subscriptionPlan !== "Free" && (
            <div className="inner-remaining-Credits ">
              <h6 className="Credits-header-text">Credits renew on</h6>
              <div className="Credits-header-months">
                {/* {day ? day : hours ? hours : mint ? mint : seconds}{" "}
                {day ? "Days" : hours ? "Hours" : mint ? "Minutes" : "Seconds"} */}
                {expiryDate}
              </div>
            </div>
          )}
          <div className="parent-updatePlan">
            {/* <Link to={"/subscription-plan"} style={{ textDecoration: "none" }}> */}
            {/* <UpgradeModal
disabledButton={disabledButton}/> */}
            <ButtonComponent
              onClick={() => navigation("/subscription-plan")}
              style={{
                height: "48px",
                width: "174px",
                whiteSpace: "nowrap",
                borderRadius: "10px",
              }}
              // src={"/Assets/Images/symbols_add.png"}
              text={
                userData?.user_metadata?.subscriptionPlan === "Pro"
                  ? "Change plan"
                  : "Upgrade plan"
              }
              btnclass="btn-background"
            />
            {/* <StripeComponentModal
              updateFromModal={true}
              userData={userData}
              setApiCall={setApiCall}
              apiCall={apiCall}
              setUserData={setUserData}
            /> */}
            {/* </Link> */}
            {userData?.user_metadata?.subscriptionPlan !== "Free" && (
              <CancelModal
                onSubscribtionEnd={onSubscribtionEnd}
                cancelLoader={cancelLoader}
              />
            )}
            {/* <img src="./Assets/Images/cancelBtn.png" alt="cancel" className="cancel-button" /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CurrentPlan;
