import { Tooltip } from "antd";
import UpgradeModal from "../../Components/UpgradeModal";
const CustomTitle = ({ row }) => (
  <Tooltip placement="topLeft" title={row} arrow={true}>
    <div className="platform-text-show">{row}</div>
  </Tooltip>
);
const Twitter = (userData, setUserData, disabledButton) => {
  let obj = {
    name: "Twitter",
    url: "twitter-dashboard",
    previousDataUrl: (user) => `/twitter-results/?user=${user}`,
    // previousDataUrl: (user) => `/twitter-results/?user=ehtishammubarik`,
    websocketUrl: `${process.env.REACT_APP_WEBSOCKET}/twitter-search-socket`,
    websocketTestUrl: `${process.env.REACT_APP_API_URL}/twitter-test-socket`,
    columns: [
      {
        name: "URL",
        selector: (row) => row?.url,
        style: {
          width: "240px", // override the row height
        },
        cell: (row) => (
          <Tooltip placement="topLeft" title={`${row.url}`} arrow={true}>
            <a
              href={row?.url}
              className="platforms-Url platform-text-show"
              target="_blank"
            >
              {row?.url}
            </a>
          </Tooltip>
        ),
        value: "url",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Name",
        selector: (row) => row?.name,
        cell: (row) => <CustomTitle row={row?.name} />,
        value: "name",
        style: {
          width: "150px", // override the row height
        },
        sortable: disabledButton ? false : true,
      },
      {
        name: "Username",
        selector: (row) => row.username,
        cell: (row) => <CustomTitle row={row.username} />,
        value: "username",
        style: {
          width: "150px", // override the row height
        },
        sortable: disabledButton ? false : true,
      },
      {
        name: "Email",
        selector: (row) => row.email,
        // cell: (row) => <CustomTitle row={row.email} />,
        cell: (row) => (
          <Tooltip
            placement="topLeft"
            title={row.email?.join(" , ")}
            arrow={true}
          >
            <div className="platform-text-show">
              {row.email?.length
                ? row.email?.map((url, index) => (
                    <span>
                      {url}
                      {row.email?.length - 1 === index ? "" : ","}{" "}
                    </span>
                  ))
                : null}
            </div>
          </Tooltip>
        ),
        value: "email",
        style: {
          width: "240px", // override the row height
        },
        sortable: disabledButton ? false : true,
      },
      {
        name: "Followers",
        selector: (row) => row?.followers,
        cell: (row) => <CustomTitle row={row?.followers} />,
        value: "followers",
        sortable: disabledButton ? false : true,
        style: {
          width: "150px", // override the row height
        },
      },
      // {
      //   name: "Friends",
      //   selector: (row) => row?.friends,
      //   cell: (row) => <CustomTitle row={row?.friends} />,
      //   value: "followers",
      //   sortable: disabledButton ? false : true,
      // },
      // {
      //   name: "Favorites",
      //   selector: (row) => row?.favorites,
      //   cell: (row) => <CustomTitle row={row?.favorites} />,
      //   value: "followers",
      //   sortable: disabledButton ? false : true,
      // },
      // {
      //   name: "Media",
      //   selector: (row) => row?.media,
      //   cell: (row) => <CustomTitle row={row?.media} />,
      //   value: "followers",
      //   sortable: disabledButton ? false : true,
      // },
      {
        name: "Location",
        selector: (row) => row.location,
        cell: (row) => <CustomTitle row={row.location} />,
        value: "location",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Description",
        selector: (row) => row.description,
        cell: (row) => <CustomTitle row={row.description} />,
        value: "description",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      // {
      //   name: "Send message",
      //   selector: (row) => {
      //     return (
      //       <UpgradeModal
      //         disabledButton={disabledButton}
      //         cookieName={"twitter_auth_token"}
      //         cookieName2={"twitter_twid"}
      //         src="/Assets/Images/components/Sendmsg.png"
      //         name={"Twitter"}
      //         socailIcon={"twitter"}
      //         premium={
      //           userData?.user_metadata?.subscriptionPlan === "Free" ||
      //           !userData?.user_metadata?.subscriptionPlan
      //             ? true
      //             : false
      //         }
      //         uid={row?.uid}
      //         userData={userData}
      //         setUserData={setUserData}
      //         chatSocketUrl={`${process.env.REACT_APP_WEBSOCKET}/twitter-send-message`}
      //       />
      //     );
      //   },
      //   value: "Send Message",
      //   sortable: false,
      // },
    ],
  };
  return obj;
};
export { Twitter };
