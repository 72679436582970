import { Tooltip } from "antd";
const CustomTitle = ({ row }) => (
  <Tooltip placement="topLeft" title={row} arrow={true}>
    <div className="platform-text-show">{row}</div>
  </Tooltip>
);
const SpotifyPlaylists = (userData, setUserData, disabledButton) => {
  let obj = {
    name: "Spotify playlists",
    url: "spotifyplaylist-dashboard",
    previousDataUrl: (user) =>
      `/spotify-results/?user=${user}&result_type=Spotify_Playlists`,
    websocketUrl: `${process.env.REACT_APP_WEBSOCKET}/spotify-playlist-socket`,
    websocketTestUrl: `${process.env.REACT_APP_API_URL}/spotify-playlists-test-socket`,
    resultType: "Spotify_Playlists",
    columns: [
      {
        name: "URL",
        selector: (row) => row.url,
        cell: (row) => (
          <Tooltip placement="topLeft" title={`${row.url}`} arrow={true}>
            <a
              href={row.url}
              className="platforms-Url platform-text-show"
              target="_blank"
            >
              {row.url}
            </a>
          </Tooltip>
        ),
        value: "url",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Name",
        selector: (row) => row.name,
        cell: (row) => <CustomTitle row={row.name} />,
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "150px", // override the row height
        },
      },
      {
        name: "Creator",
        selector: (row) => row.creator,
        cell: (row) => <CustomTitle row={row.creator} />,
        value: "creator",
        sortable: disabledButton ? false : true,
        style: {
          width: "150px", // override the row height
        },
      },
      {
        name: "Likes",
        selector: (row) => parseInt(row.likes),
        cell: (row) => <CustomTitle row={parseInt(row.likes)} />,
        value: "likes",
        sortable: disabledButton ? false : true,
        style: {
          width: "150px", // override the row height
        },
      },
      {
        name: "Email",
        selector: (row) => row.email,
        cell: (row) => (
          <Tooltip placement="topLeft" title={row.email?.join(" , ")} arrow={true}>
            <div className="platform-text-show">
              {row?.email?.length
                ? row?.email?.map((url, index) => (
                    <span>
                      {url}
                      {row?.email?.length - 1 === index ? "" : ","}{" "}
                    </span>
                  ))
                : null}
            </div>
          </Tooltip>
        ),
        value: "email",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Instagram",
        selector: (row) => row.instagram,
        cell: (row) => <CustomTitle row={row.instagram} />,
        value: "instagram",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Genres",
        selector: (row) => row.genres,
        cell: (row) => (
          <Tooltip placement="topLeft" title={row.genres} arrow={true}>
            <div className="platform-text-show">
              {row?.genres?.length
                ? row?.genres?.map((url, index) => (
                    <span>
                      {url}
                      {row?.genres?.length - 1 === index ? "" : ","}{" "}
                    </span>
                  ))
                : null}
            </div>
          </Tooltip>
        ),
        value: "genres",
        sortable: disabledButton ? false : true,
        style: {
          width: "150px", // override the row height
        },
      },
      {
        name: "Description",
        selector: (row) => row.description,
        cell: (row) => <CustomTitle row={row.description} />,
        value: "description",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
    ],
  };
  return obj;
};
export { SpotifyPlaylists };
